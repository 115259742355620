
/**************************Number Counter ANIMATION in Home*****************************/
const counters = document.querySelectorAll('.number');
const speed = 200;

if(counters != null ){
    counters.forEach( counter => {
            let value = +counter.getAttribute('akhi');
            let signo = ''
            if ( counter.getAttribute('akhi').toString().indexOf('+') > 0 ) {
                signo = counter.getAttribute('akhi').toString().split('+');
                value = parseInt(signo[0])
            }
            const animate = () => {
                const data = +counter.innerText;
    
                const time = value / speed;
                if(data < value) {
                    counter.innerText = Math.ceil(data + time);
                    setTimeout(animate, 1);
                }   else{
                    if (signo !== '') {
                        counter.innerHTML = value + '<span>+</span>';
                    } else {
                        counter.innerText = value;
                    }
                }
                
            }
       
       animate();
    });
}

/******************************************END ANIMATION**********************************/

/****************************SLIDER PAGE CAREERS IN MOBILE******************************/
const wtf = document.querySelector('.container-slide-careers');

if(wtf != null ){
    tns({
        container: '.container-slide-careers',
        items: 1,
        autoplay: true,
        mouseDrag: true,
        responsive: {
            769: {
                disable: true,
            }
        },
    });
}

/******************************************END SLIDER**********************************/
